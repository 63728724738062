import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.detail === 'size')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(_ctx.$n(_ctx.diamond[_ctx.detail], "carat")) + " ct. ", 1)
        ], 64))
      : (_ctx.detail === 'dimension')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$n(_ctx.diamond["width"])) + "mm x " + _toDisplayString(_ctx.$n(_ctx.diamond["length"])) + "mm x " + _toDisplayString(_ctx.$n(_ctx.diamond["height"])) + "mm (" + _toDisplayString(_ctx.$t("details.fullSpecs.widthLengthHeight")) + ") ", 1)
          ], 64))
        : (_ctx.detail === 'institute')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.diamond["institute"]) + " ", 1),
              (_ctx.certificateUrl !== '')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.certificateUrl,
                    target: "_blank",
                    class: "ml-2 text-brand-main font-medium"
                  }, "> " + _toDisplayString(_ctx.$t("details.fullSpecs.instituteReportLink")), 9, _hoisted_1))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.$te('details.' + _ctx.detail + '.' + _ctx.diamond[_ctx.detail]))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.$t("details." + _ctx.detail + "." + _ctx.diamond[_ctx.detail])), 1)
              ], 64))
            : (_ctx.detail === 'color')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                  _createTextVNode(_toDisplayString(_ctx.diamond[_ctx.detail]) + " ", 1),
                  (_ctx.diamond.fancy_intensity && _ctx.diamond.fancy_intensity != '')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode("(" + _toDisplayString(_ctx.diamond.fancy_intensity) + ")", 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                  _createTextVNode(_toDisplayString(_ctx.diamond[_ctx.detail]), 1)
                ], 64))
  ]))
}