import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative z-0 flex flex-wrap" }
const _hoisted_2 = ["onClick", "onMouseenter"]
const _hoisted_3 = {
  key: 0,
  class: "rounded-md insert-0 text-center p-1 w-full h-full flex items-center overflow-hidden bg-white"
}
const _hoisted_4 = { class: "w-full text-xs text-center tracking-wide font-light text-gray-400" }
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (valueText, valueIdx) => {
      return (_openBlock(), _createElementBlock("button", {
        key: 'value' + valueIdx,
        type: "button",
        onClick: ($event: any) => (_ctx.toggleParameter(valueIdx)),
        class: _normalizeClass(["flex bg-white border border-brand-main rounded-md relative mr-1 mb-1 p-1 px-2 uppercase text-brand-text font-light text-xs tracking-wide whitespace-nowrap", {
        '': !_ctx.parameter.includes(valueIdx),
        'bg-brand-light': _ctx.parameter.includes(valueIdx),
      }]),
        onMouseenter: ($event: any) => (_ctx.showText = valueIdx),
        onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showText = ''))
      }, [
        (_ctx.filterId === 'form' && _ctx.showText === valueIdx)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("searchForm.parts." + _ctx.filterId + ".types." + valueIdx)), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.type === 'icon')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: 
          _ctx.Images.get(
            '/img/filter/' + _ctx.filterId + '/' + valueIdx.toLowerCase() + '.svg'
          )
        ,
              class: _normalizeClass(["h-8 mx-auto my-auto text-white rounded-md", {
          'py-2 px-1': _ctx.filterId === 'institute',
          'h-8': _ctx.filterId !== 'institute',
        }]),
              alt: valueIdx.toLowerCase()
            }, null, 10, _hoisted_5))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(valueText), 1)
            ], 64))
      ], 42, _hoisted_2))
    }), 128))
  ]))
}