type searchParameterType = {
  type: string;
  size: {
    from: number;
    till: number;
  };
  form: string[];
  color: string[];
  colored: boolean;
  fancy_intensity: string[];
  clarity: string[];
  cut: string[];
  polish: string[];
  symmetry: string[];
  fluorescence: string[];
  price: {
    from: number;
    till: number;
  };
  institute: string[];
  fastDelivery: boolean;
  page: {
    current: number;
    results: number;
  };
  order: {
    by: string;
    sort: string;
  };
};
const defaultState: searchParameterType = {
  type: "earth",
  size: {
    from: 0.0,
    till: 0.0,
  },
  form: ["BR"],
  color: ["G", "F", "E", "D"],
  colored: false,
  fancy_intensity: [],
  clarity: [],
  cut: ["EX", "ID"],
  polish: ["EX"],
  symmetry: ["EX"],
  fluorescence: ["N"],
  price: {
    from: 0,
    till: 0,
  },
  institute: ["GIA"],
  fastDelivery: false,
  page: {
    current: 1,
    results: 16,
  },
  order: {
    by: "price",
    sort: "asc",
  },
};

const state = (): searchParameterType => ({
  type: "earth",
  size: {
    from: 0.0,
    till: 0.0,
  },
  form: ["BR"],
  color: ["G", "F", "E", "D"],
  colored: false,
  fancy_intensity: [],
  clarity: [],
  cut: ["EX"],
  polish: ["EX"],
  symmetry: ["EX"],
  fluorescence: ["N"],
  price: {
    from: 0,
    till: 0,
  },
  institute: ["GIA"],
  fastDelivery: false,
  page: {
    current: 1,
    results: 8,
  },
  order: {
    by: "price",
    sort: "asc",
  },
});

const getters = {
  getDefaultState(): searchParameterType {
    return JSON.parse(JSON.stringify(defaultState));
  },
  get(state: searchParameterType): searchParameterType {
    return state;
  },
  getAsUri(state: searchParameterType): { [key: string]: string | number } {
    const queryParam: { [key: string]: string | number } = {};

    if (JSON.stringify(state.form) !== JSON.stringify(defaultState.form))
      queryParam.fo = state.form.join(",");

    if (JSON.stringify(state.color) !== JSON.stringify(defaultState.color))
      queryParam.co = state.color.join(",");

    if (JSON.stringify(state.clarity) !== JSON.stringify(defaultState.clarity))
      queryParam.cl = state.clarity.join(",");

    if (JSON.stringify(state.cut) !== JSON.stringify(defaultState.cut))
      queryParam.cu = state.cut.join(",");

    if (JSON.stringify(state.polish) !== JSON.stringify(defaultState.polish))
      queryParam.po = state.polish.join(",");

    if (
      JSON.stringify(state.symmetry) !== JSON.stringify(defaultState.symmetry)
    )
      queryParam.sy = state.symmetry.join(",");

    if (
      JSON.stringify(state.fancy_intensity) !==
      JSON.stringify(defaultState.fancy_intensity)
    )
      queryParam.ci = state.fancy_intensity.join(",");

    if (JSON.stringify(state.colored) !== JSON.stringify(defaultState.colored))
      queryParam.c = state.colored ? "y" : "n";

    if (
      JSON.stringify(state.fluorescence) !==
      JSON.stringify(defaultState.fluorescence)
    )
      queryParam.fl = state.fluorescence.join(",");

    if (
      JSON.stringify(state.institute) !== JSON.stringify(defaultState.institute)
    )
      queryParam.in = state.institute.join(",");

    if (state.price.from !== defaultState.price.from)
      queryParam.pf = state.price.from;
    if (state.price.till !== defaultState.price.till)
      queryParam.pt = state.price.till;
    if (state.size.from !== defaultState.size.from)
      queryParam.sf = state.size.from;
    if (state.size.till !== defaultState.size.till)
      queryParam.st = state.size.till;

    if (state.fastDelivery !== defaultState.fastDelivery) queryParam.fd = "yes";
    if (state.type != defaultState.type) queryParam.t = state.type;

    if (state.order.by !== defaultState.order.by) queryParam.b = state.order.by;
    if (state.order.sort !== defaultState.order.sort)
      queryParam.s = state.order.sort;

    return queryParam;
  },
};

const actions = {
  setFullState(context: any, newState: searchParameterType) {
    context.commit("setFullState", newState);
  },
  setPage(context: any, newPage: number) {
    context.commit("setPage", newPage);
  },
  setSortBy(context: any, sortBy: string) {
    context.commit("setSortBy", sortBy);
  },
};

const mutations = {
  toggleValue(state: any, value: { name: string; value: string }): void {
    if (value.name == "form") {
      if (
        state["type"] != "lab" &&
        value.name === "form" &&
        value.value !== "BR" &&
        state[value.name] == "BR"
      ) {
        state.cut = ["VG", "EX", "ID"];
        state.polish = ["VG", "EX"];
        state.symmetry = ["VG", "EX"];
        state.fluorescence = ["N"];
        state.institute = ["GIA"];
        state.clarity = ["VS2", "VS1", "VVS2", "VVS1", "IF", "FL"];
      } else if (
        state["type"] != "lab" &&
        value.name === "form" &&
        value.value == "BR" &&
        state[value.name] !== "BR"
      ) {
        state.cut = ["EX", "ID"];
        state.polish = ["EX"];
        state.symmetry = ["EX"];
        state.fluorescence = ["N"];
        state.clarity = [];
        state.institute = ["GIA"];
      }

      state[value.name] = [value.value];
    } else if (typeof state[value.name] === "object") {
      if (!state[value.name].includes(value.value)) {
        state[value.name].push(value.value);
      } else {
        state[value.name] = state[value.name].filter((curValue: string) => {
          return value.value != curValue;
        });
      }
    } else if (typeof state[value.name] === "boolean") {
      if (value.name === "colored" && value.value.toString() === "true") {
        state.cut = [];
        state.color = [];
        state.clarity = [];
        state.cut = [];
        state.polish = [];
        state.symmetry = [];
        state.fluorescence = [];
        state.institute = ["IGI"];
      }

      state[value.name] = value.value.toString() === "true";
    } else if (typeof state[value.name] === "string") {
      if (value.name === "type" && value.value === "lab") {
        state.cut = [];
        state.color = ["E", "D"];
        state.clarity = ["VS2", "VS1", "VVS2", "VVS1", "IF", "FL"];
        state.cut = ["EX", "ID"];
        state.polish = ["EX"];
        state.symmetry = ["EX"];
        state.fluorescence = ["N"];
        state.institute = ["IGI"];
      } else if (value.name === "type" && value.value === "earth") {
        state.color = ["G", "F", "E", "D"];
        state.cut = ["EX", "ID"];
        state.polish = ["EX"];
        state.symmetry = ["EX"];
        state.fluorescence = ["N"];
        state.clarity = [];
        state.institute = ["GIA"];
      }

      state[value.name] = value.value;
    }

    state.page.current = 1;
  },
  setValuesList(state: any, value: { name: string; value: string }): void {
    if (typeof state[value.name] === "object") {
      state[value.name] = value.value;
      state.page.current = 1;
    }
  },
  clear(state: searchParameterType): void {
    state.type = "earth";
    state.size.from = 0;
    state.size.till = 0;
    state.form = [];
    state.color = [];
    state.colored = false;
    state.fancy_intensity = [];
    state.clarity = [];
    state.cut = [];
    state.polish = [];
    state.symmetry = [];
    state.fluorescence = [];
    state.price.from = 0;
    state.price.till = 0;
    state.institute = [];
    state.fastDelivery = false;
    state.page.current = 1;
    state.page.results = 8;
    state.order.by = "price";
    state.order.sort = "asc";
  },
  setFullState(
    state: searchParameterType,
    newState: searchParameterType
  ): void {
    state.type = newState.type;
    state.size = newState.size;
    state.form = newState.form;
    state.color = newState.color;
    state.colored = newState.colored;
    state.fancy_intensity = newState.fancy_intensity;
    state.clarity = newState.clarity;
    state.cut = newState.cut;
    state.polish = newState.polish;
    state.symmetry = newState.symmetry;
    state.fluorescence = newState.fluorescence;
    state.price = newState.price;
    state.institute = newState.institute;
    state.fastDelivery = newState.fastDelivery;
    state.page.current = newState.page.current;
    state.page.results = newState.page.results;
    state.order.by = newState.order.by;
    state.order.sort = newState.order.sort;
  },
  setPage(state: searchParameterType, page: number): void {
    if (page > 0) {
      state.page.current = page;
    } else {
      state.page.current = 1;
    }
  },
  setSortBy(state: searchParameterType, sortBy: string): void {
    switch (sortBy) {
      case "priceASC":
        state.order.by = "price";
        state.order.sort = "asc";
        break;
      case "priceDESC":
        state.order.by = "price";
        state.order.sort = "desc";
        break;
      case "caratASC":
        state.order.by = "size";
        state.order.sort = "asc";
        break;
      case "caratDESC":
        state.order.by = "size";
        state.order.sort = "desc";
        break;
    }

    state.page.current = 1;
  },
  setPageResults(state: searchParameterType, resultsPerPage: number): void {
    if (resultsPerPage > 0) {
      state.page.results = resultsPerPage;
    } else {
      state.page.current = 8;
    }
  },
  setPrice(
    state: searchParameterType,
    price: { from: number; till: number }
  ): void {
    if (isNaN(parseFloat(String(price.from)))) {
      price.from = 0;
    }

    if (isNaN(parseFloat(String(price.till)))) {
      price.till = 0;
    }

    state.price.from = price.from < 0 ? 0 : price.from;
    state.price.till = price.till < price.from ? 0 : price.till;

    state.page.current = 1;
  },
  setSize(
    state: searchParameterType,
    size: { from: number; till: number }
  ): void {
    state.size.from = size.from < 0 ? 0 : size.from;
    state.size.till = size.till < size.from ? 0 : size.till;

    state.page.current = 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
